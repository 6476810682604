<div class="sidenav">
  <div class="head">Report Actions</div>
  <a routerLink="/dboard" [ngClass]="{'disabled': (disableCompanylist==true)}" class="companylist">PRF List</a>
  <ul>

    <ng-container *ngIf="companyInformationCompleted;else companyInformationInProgressSecond">
      <li [ngClass]="{'completed':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/companyinformation">Company
          Information </a></li>
    </ng-container>
    <ng-template #companyInformationInProgressSecond>
      <ng-container *ngIf="companyInformationInProgress;else companyInformationNone">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/companyinformation">Company
            Information </a></li>
      </ng-container>
    </ng-template>
    <ng-template #companyInformationNone>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disableCinformation==true)}"
          routerLink="/companyinformation">Company
          Information </a></li>
    </ng-template>

    <ng-container *ngIf="groupreportingCompleted;else groupreportingCompletedsecond">
      <li [ngClass]="{'completed':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/groupreporting">Group
          Reporting</a></li>
    </ng-container>
    <ng-template #groupreportingCompletedsecond>
      <ng-container *ngIf="nogroupbullet();else groupreportingCompletedthird">
        <li [ngClass]="{'nogroup':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
            routerLink="/groupreporting">Group
            Reporting</a></li>
      </ng-container>
    </ng-template>
    <ng-template #groupreportingCompletedthird>
      <ng-container *ngIf="groupreportingInProgress;else groupreportingInProgressthird">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/groupreporting">Group
            Reporting</a></li>
      </ng-container>
    </ng-template>
    <ng-template #groupreportingInProgressthird>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disableGReport==true)}"
          routerLink="/groupreporting">Group Reporting</a>
      </li>
    </ng-template>

    <ng-container *ngIf="annualstatementCompleted;else annualstatementCompletedsecond">
      <li [ngClass]="{'completed':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/annualstatement">Annual
          Statement</a></li>
    </ng-container>
    <ng-template #annualstatementCompletedsecond>
      <ng-container *ngIf="annualstatementInProgress;else annualstatementInProgressthird">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/annualstatement">Annual
            Statement</a></li>
      </ng-container>
    </ng-template>
    <ng-template #annualstatementInProgressthird>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disableAStatement==true)}"
          routerLink="/annualstatement">Annual
          Statement</a></li>
    </ng-template>

    <ng-container *ngIf="premiumreportCompleted;else premiumreportCompletedsecond">

      <li [ngClass]="{'completed':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/premiumreport">Premium
          Report</a></li>
    </ng-container>
    <ng-template #premiumreportCompletedsecond>

      <ng-container *ngIf="premiumreportInProgress;else premiumreportInProgressthird">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/premiumreport">Premium
            Report</a></li>
      </ng-container>
    </ng-template>
    <ng-template #premiumreportInProgressthird>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disablePReport==true)}"
          routerLink="/premiumreport">Premium Report </a>
      </li>
    </ng-template>
    <ng-container
      *ngIf="userdefineddataCompleted; else userdefineddataCompletedsecond"
    >
      <li [ngClass]="{ completed: true }" routerLinkActive="active">
        <a [ngClass]="{ completed: true }" routerLink="/userdefineddata"
          >User Defined Data</a
        >
      </li>
    </ng-container>
    <ng-template #userdefineddataCompletedsecond>
      <ng-container *ngIf="userdefineddataInProgress;else userdefineddataCompletedThird">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/userdefineddata">User
            Defined Data</a></li>
      </ng-container>
    </ng-template>
    <ng-template #userdefineddataCompletedThird>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disableUData==true)}" routerLink="/userdefineddata">User
          Defined Data</a></li>
    </ng-template>

    <ng-container *ngIf="commentsCompleted;else commentsCompletedsecond">
      <li [ngClass]="{'completed':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/comments">Comments</a>
      </li>
    </ng-container>
    <ng-template #commentsCompletedsecond>
      <ng-container *ngIf="commentsInProgress;else commentsCompletedthird">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/comments">Comments</a>
        </li>
      </ng-container>
    </ng-template>
    <ng-template #commentsCompletedthird>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disableComments==true)}"
          routerLink="/comments">Comments</a></li>
    </ng-template>


    <!-- Annual Statement Comparison -->


    <ng-container *ngIf="annualstatementCompleted;else AStatementCompletedsecond">
      <li [ngClass]="{'completed':true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/annualstatementcomparison">Annual
          Statement Comparison</a>
      </li>
    </ng-container>
    <ng-template #AStatementCompletedsecond>
      <ng-container *ngIf="annualstatementInProgress;else AStatementCompletedthird">
        <li [ngClass]="{'progressi': true}" routerLinkActive="active"><a [ngClass]="{'progressa': true}"
            routerLink="/annualstatementcomparison">Annual
            Statement Comparison</a>
        </li>
      </ng-container>
    </ng-template>
    <ng-template #AStatementCompletedthird>
      <li routerLinkActive="active"><a [ngClass]="{'disabled': (disableAStatement==true)}"
          routerLink="/annualstatementcomparison">Annual
          Statement Comparison</a>
      </li>
    </ng-template>
    <!-- Prior Year Comparison -->

    <li
      routerLinkActive="active"
      [ngClass]="{
        completed: premiumYearComparisonCompleted && !this.disablePriorYearComparison ,
        disabled: this.disablePriorYearComparison || !premiumYearComparisonCompleted
      }"
    >
      <a
        [ngClass]="{
          completed: premiumYearComparisonCompleted && !this.disablePriorYearComparison,
          disabled: this.disablePriorYearComparison || !premiumYearComparisonCompleted
        }"
        routerLink="/prioryearcomparison"
        >Prior Year Comparison</a
      >
    </li>
    <li
      [ngClass]="{'completed': (isValidationJobIdNotEmptySelector$|async) && !this.disableValidationSummary, 'disabled': this.disableValidationSummary || !(isValidationJobIdNotEmptySelector$|async)}"
      routerLinkActive="active">
      <a [ngClass]="{'completed': isValidationJobIdNotEmptySelector$|async, 'disabled': this.disableValidationSummary || !(isValidationJobIdNotEmptySelector$|async)}"
        routerLink="/validationsummary">Validation Summary</a>
    </li>



    <!-- <ng-container *ngIf="disableAStatement;else AStatementCompleted">
      <li><a [ngClass]="{'disabled': (disableAStatement==true)}" routerLink="/annualstatementcomparison">Annual
          Statement Comparison</a>
      </li>
    </ng-container>
    <ng-template #AStatementCompleted>
      <li [ngClass]="{'completed': true}" routerLinkActive="active"><a [ngClass]="{'completed': true}"
          routerLink="/annualstatementcomparison">Annual
          Statement Comparison</a>
      </li>
    </ng-template> -->



  </ul>
  <div *ngIf="!isReadOnlyAdmin"
  style="display: flex; justify-content: center;padding-top: 14px;padding-right: 10px;"><button
      (click)="openFinalSubmission()" class="submitreport active" [ngClass]="{active: ((premiumreportCompleted && companyInformationCompleted)==true),
        disabled: !(premiumreportCompleted && companyInformationCompleted)}"
      [disabled]="((premiumreportCompleted && companyInformationCompleted)==false)"
      *ngIf="!(isLockedForValisation$ | async)">Submit
      Report</button>

    <app-button buttonType="secondary" *ngIf="(isLockedForValisation$ | async) && (!disablePReport)" routerLink="/validationsummary">Validation
      Summary</app-button>
  </div>
  <div class="helplink"><a (click)="openInNew(helpUrl)">How This
      Works</a>
  </div>
</div>
