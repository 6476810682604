import { Injectable } from '@angular/core';
import { IgxGridComponent } from '@infragistics/igniteui-angular';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class Premiumreportservice {
  params: premiumreportParams;
  public FIXED_COLUMNS = 3;
  public CONST_ROW_NUMBER_COLUMN = 0;
  public CONST_STATE_COLUMN = 1;
  public CONST_STATECODE_COLUMN = 2;
  public userdefined_row_code = 'UD';
  public supplemental_row_code = 'SP';
  public AssumedFromNonAffiliates_row_code = 'AN';
  public total_row_code = 'T';
  supp_editable_cols = [25, 31];

  constructor() {}

  setParams(columns, rows, userdefined_premium_columns) {
    this.params = new premiumreportParams(
      columns,
      rows,
      userdefined_premium_columns,
      this.CONST_STATECODE_COLUMN,
      this.userdefined_row_code,
      this.supplemental_row_code,
      this.FIXED_COLUMNS,
      this.AssumedFromNonAffiliates_row_code
    );
  }

  getLockedColumns(columns: any) {
    var sColumns = [...columns];
    var dColumns = [...columns];
    for (let i = 0; i < this.supp_editable_cols.length; i++) {
      const colIndex = this.supp_editable_cols[i] + 2;
      sColumns[colIndex] = {
        ...sColumns[colIndex],
        cellTypeId: '',
        editable: true,
      };

      dColumns[colIndex] = {
        ...dColumns[colIndex],
        cellTypeId: 'C',
        editable: false,
      };
    }
    return [dColumns, sColumns];
  }

  public getVisibleData$(premium$,gridConfiguration$){
    return combineLatest([
      premium$,
      gridConfiguration$,
    ]).pipe(
      map(([premium, configuration]) => {
        var newModifiedRows = this.updateRowsVisibility(
          premium,
          configuration
        );
        return newModifiedRows;
      })
    );
  }

  public updateRowsVisibility(rows, stateRows) {
    const updatedRows = rows.filter((row) => {
      const matchingValue = stateRows.find((value) => value.name === row['1']);
      return !matchingValue || matchingValue.selected;
    });

    return updatedRows;
  }

  public concatWithHiddenRows(premium, hiddenRows) {
    const concatedPremium = premium.concat(
      hiddenRows.filter(
        (hItem) => !premium.some((item) => item['1'] === hItem['1'])
      )
    );
    return concatedPremium.sort((a, b) => a['0'] - b['0']);
  }

  public updateColumnVisibility(columns, premiumColumns) {
    const updatedColumns = columns.map((column) => {
      const columnName = this.formatPremiumColumnName([
        column.Name1,
        column.Name2,
        column.Name3,
        column.Name4,
      ]);
      const matchingValue = premiumColumns.find(
        (value) => value.name === columnName
      );

      if (matchingValue && !matchingValue.selected) {
        return { ...column, hidden: true };
      } else {
        return { ...column, hidden: false };
      }
    });
    return updatedColumns;
  }

  private formatPremiumColumnName(nameParts: string[]) {
    return nameParts
      .map((part) => (part ? part.trim() : ''))
      .filter((part) => part !== '')
      .join(' ');
  }

  public updatePinnedStatus(columns) {
    return columns.map((res) => this.updateColumnDataType(res));
  }

  public updateColumnDataType(res) {
    //updating the first 3 columns as Pinned.
    res.IsPinned = res.columnNumber < this.FIXED_COLUMNS ? true : false;

    //Updating editable status of cell
    res.editable = this.isEditableCell(res);

    //Updating the datatype
    res.dataType = res.columnNumber < this.FIXED_COLUMNS ? '' : 'number';
    return res;
  }

  public isEditableCell(column) {
    if (column.columnNumber < this.FIXED_COLUMNS || column.cellTypeId === 'C') {
      return false;
    }
    return true;
  }

  IsTotalRowData(rowNumber) {
    var rowInfo = this.params.rows.filter(function (p) {
      return p.rowNumber == parseInt(rowNumber);
    });
    return (rowInfo.length > 0 && rowInfo[0].cellTypeId == 'C') == true
      ? true
      : false;
  }

  calculateRowTotal(Data) {
    // var Data = this.grid1.data;
    // Data.splice(-1, 1);
    for (let index = 0; index < Data.length; index++) {
      var rowdata = Data[index];
      var flag = this.IsTotalRowData(parseInt(rowdata[0]));
      if (flag) {
        if (
          rowdata[this.params.const_statecode_column] !=
          this.params.userdefined_row_code
        ) {
          if (
            rowdata[this.params.const_statecode_column] ==
              this.params.AssumedFromNonAffiliates_row_code ||
            rowdata[this.params.const_statecode_column] ==
              this.params.supplemental_row_code
          ) {
            for (var prop in rowdata) {
              if (
                parseInt(prop) >= this.params.const_fixed_columns &&
                (this.params.columns[prop].cellTypeId == 'D' ||
                  parseInt(prop) == this.params.columns.length - 1) &&
                (rowdata[prop] == null || rowdata[prop] == 0)
              ) {
                rowdata[prop] = null;
              }
            }
          } else {
            rowdata = this.resetTotal(rowdata);
            var formula = this.GetTotalRowFormula(parseInt(rowdata[0]));

            if (formula.indexOf(',') > 0) {
              var split = formula.split(',');

              var tempdata = rowdata;
              for (let i = 0; i < split.length; i++) {
                const _formula = split[i];
                tempdata = this.getRowCellTotal(_formula, tempdata, Data);
              }
              rowdata = tempdata;
            } else {
              rowdata = this.getRowCellTotal(formula, rowdata, Data);
            }
          }
        }
      }
    }
    // this.grid1.data = Data;
    return Data;
  }

  updateColumnTotal(
    griddata,
    premium_type,
    supplemental_premium,
    userdefined_premium,
    userdefined_premium_supplemental
  ) {
    for (let index = 0; index < griddata.length; index++) {
      griddata[index] = this.calculateColumnwiseTotal(
        griddata[index],
        premium_type,
        supplemental_premium,
        userdefined_premium,
        userdefined_premium_supplemental
      );
    }
    return griddata;
  }

  calculateColumnwiseTotal(
    rowData,
    premium_type,
    supplemental_premium,
    userdefined_premium,
    userdefined_premium_supplemental
  ) {
    for (var prop in rowData) {
      var columnInfo = this.params.columns.filter((p) => {
        return p.columnNumber == prop;
      });
      if (columnInfo != null && columnInfo.length > 0) {
        if (
          columnInfo[0].cellTypeId == 'C' &&
          columnInfo[0].cellformulae.length > 0
        ) {
          //Avoid calculating the total for user defined and supplemental premium for the last column
          if (
            columnInfo[0].columnNumber == this.params.columns.length - 1 &&
            (rowData[this.params.const_statecode_column] ==
              this.params.userdefined_row_code ||
              rowData[this.params.const_statecode_column] ==
                this.params.supplemental_row_code)
          ) {
            rowData[prop] = null;
            continue;
          }
          if (columnInfo[0].cellformulae.indexOf(',') > 0) {
            var split = columnInfo[0].cellformulae.split(',');
            var total = 0;
            for (let index = 0; index < split.length; index++) {
              const element = split[index];
              total += this.getCellTotalRow(element, rowData);
            }
            rowData[prop] = total;
          } else {
            rowData[prop] = this.getCellTotalRow(
              columnInfo[0].cellformulae,
              rowData
            );
          }
          if (premium_type == 'D') {
            if (parseInt(prop) == this.params.columns.length - 1) {
              //Adding supplemental premium to the last column
              var values = supplemental_premium.filter(function (a) {
                if (a[0] == rowData[0]) {
                  return a;
                }
              });
              if (values.length > 0) {
                rowData[prop] +=
                  values[0][prop] == null ? 0 : parseInt(values[0][prop]);
              }

              //Adding user defined premium to the last column
              var ud = userdefined_premium.filter(function (a) {
                if (a[0] == rowData[0]) {
                  return a;
                }
              });
              if (
                ud.length > 0 &&
                ud[0][this.params.userdefined_premium_columns.length - 1] !=
                  null
              ) {
                var val =
                  ud[0][this.params.userdefined_premium_columns.length - 1];
                rowData[prop] += val == null ? 0 : parseInt(val);
              }
            }
          }
        }
      }
    }
    if (
      premium_type == 'S' &&
      rowData[this.params.const_statecode_column] !=
        this.params.userdefined_row_code &&
      rowData[this.params.const_statecode_column] !=
        this.params.supplemental_row_code
    ) {
      //Adding user defined supplemental premium to the row total
      var values = userdefined_premium_supplemental.filter(function (a) {
        if (a[0] == rowData[0]) {
          return a;
        }
      });
      if (values.length > 0) {
        var udcolslen = this.params.userdefined_premium_columns.length - 1;
        rowData[prop] +=
          values[0][udcolslen] == null ? 0 : parseInt(values[0][udcolslen]);
      }
    }
    return rowData;
  }

  getCellTotalRow(cellformula, rowData) {
    var formula = cellformula.split(':');
    var columnVal = 0;
    for (
      let i = parseInt(formula[0]);
      i <= parseInt(formula[formula.length - 1]);
      i++
    ) {
      //columnVal += parseInt(rowData[i]);
      columnVal += rowData[i] == null ? 0 : parseInt(rowData[i]);
    }
    return columnVal;
  }

  resetTotal(columnTotal) {
    // // var columnTotal = Data[Data.length - 1];
    //assign 0 to column total
    for (var prop in columnTotal) {
      var columnInfo = this.params.columns.filter((p) => {
        return p.columnNumber == prop;
      });
      if (columnInfo != null && columnInfo.length > 0) {
        //if (columnInfo[0].dataType == "number") {
        if (columnInfo[0].columnNumber >= this.params.const_fixed_columns) {
          columnTotal[prop] = 0;
        }
      }
    }
    return columnTotal;
  }

  GetTotalRowFormula(rowNumber) {
    var rowInfo = this.params.rows.filter(function (p) {
      return p.rowNumber == parseInt(rowNumber);
    });
    return (rowInfo.length > 0 && rowInfo[0].cellTypeId == 'C') == true
      ? rowInfo[0].cellformulae
      : '';
  }

  private getRowCellTotal(rowFormula, element, Data) {
    var formulaSplit = rowFormula.split(':');
    var start = parseInt(formulaSplit[0]);
    var end = parseInt(formulaSplit[1]);
    for (var prop in element) {
      if (Object.prototype.hasOwnProperty.call(element, prop)) {
        if (parseInt(prop) >= this.params.const_fixed_columns) {
          //Avoiding fixed columns
          var values = Data.filter(function (a) {
            if (a[0] >= start && a[0] <= end) {
              return a;
            }
          });
          var total = this.getSum(values, prop);
          var value = parseInt(element[prop]);
          if (isNaN(value)) {
            value = 0;
          }
          element[prop] = value + total;
        }
      }
    }
    return element;
  }

  getSum(array, prop) {
    var total = 0;
    for (var i = 0, _len = array.length; i < _len; i++) {
      var val = array[i][prop];
      total += val == null || val == undefined ? 0 : parseInt(val);
    }
    return total;
  }

  updateUDPremiumRow(
    premium_type,
    direct_premium,
    supplemental_premium,
    userdefined_premium,
    userdefined_premium_supplemental
  ) {
    if (premium_type == 'D') {
      //Updating user defined row in dpremium
      var dUDRowIndex = direct_premium.findIndex(
        (x) =>
          x[this.params.const_statecode_column] ===
          this.params.userdefined_row_code
      );
      var sUDRowIndex = direct_premium.findIndex(
        (x) =>
          x[this.params.const_statecode_column] ===
          this.params.supplemental_row_code
      );
      var udpremium = userdefined_premium[this.params.rows.length - 1];
      var udsupppremium = userdefined_premium[sUDRowIndex];
      if (dUDRowIndex != -1) {
        //clear row values first
        for (
          let i = this.params.const_fixed_columns;
          i < this.params.columns.length - 1;
          i++
        ) {
          direct_premium[dUDRowIndex][i] = null;
        }
        for (
          let index = this.params.const_fixed_columns;
          index < this.params.userdefined_premium_columns.length - 1;
          index++
        ) {
          var udcolinfo = this.params.userdefined_premium_columns[index];
          if (
            udcolinfo != null &&
            udcolinfo.MatchCol &&
            parseInt(udcolinfo.MatchCol) > 0
          ) {
            var actualcol =
              parseInt(udcolinfo.MatchCol) +
              (this.params.const_fixed_columns - 1);
            var uddirect = udpremium[index] - udsupppremium[index];
            direct_premium[dUDRowIndex][actualcol] += uddirect;
          }
        }
        //Updating supplemental row last cell to zero
        direct_premium[dUDRowIndex][this.params.columns.length - 1] = 0;
      }
    } else {
      //Updating user defined row in spremium
      var suppUDRowIndex = supplemental_premium.findIndex(
        (x) =>
          x[this.params.const_statecode_column] ===
          this.params.userdefined_row_code
      );
      var udpremium =
        userdefined_premium_supplemental[this.params.rows.length - 1];
      if (suppUDRowIndex != -1) {
        //clear row values first
        for (
          let i = this.params.const_fixed_columns;
          i < this.params.columns.length - 1;
          i++
        ) {
          supplemental_premium[suppUDRowIndex][i] = null;
        }
        for (
          let index = this.params.const_fixed_columns;
          index < this.params.userdefined_premium_columns.length - 1;
          index++
        ) {
          var udcolinfo = this.params.userdefined_premium_columns[index];
          if (
            udcolinfo != null &&
            udcolinfo.MatchCol &&
            parseInt(udcolinfo.MatchCol) > 0
          ) {
            var actualcol =
              parseInt(udcolinfo.MatchCol) +
              (this.params.const_fixed_columns - 1);
            supplemental_premium[suppUDRowIndex][actualcol] += udpremium[index];
          }
        }
        //Updating supplemental row last cell to zero
        supplemental_premium[suppUDRowIndex][
          this.params.columns.length - 1
        ] = 0;
      }
    }
    return [direct_premium, supplemental_premium];
  }

  updateSuppPremiumRow(
    direct_premium,
    supplemental_premium,
    userdefined_premium_supplemental
  ) {
    //Updating supplemental row in dpremium
    var dSuppRowIndex = direct_premium.findIndex(
      (x) =>
        x[this.params.const_statecode_column] ===
        this.params.supplemental_row_code
    );
    var spremium = supplemental_premium[this.params.rows.length - 1];
    if (dSuppRowIndex != -1) {
      for (
        let index = this.params.const_fixed_columns;
        index < this.params.columns.length - 1;
        index++
      ) {
        direct_premium[dSuppRowIndex][index] = spremium[index];
      }
      //Updating supplemental row last cell to zero
      direct_premium[dSuppRowIndex][this.params.columns.length - 1] = 0;

      // //adding user defined supplemental premium
      // for (let i = this.params.const_fixed_columns; i < this.params.userdefined_premium_columns.length - 1; i++) {
      //     var udcolinfo = this.params.userdefined_premium_columns[i];
      //     if (udcolinfo != null && udcolinfo.MatchCol) {
      //         var actualcol = parseInt(udcolinfo.MatchCol) + (this.params.const_fixed_columns - 1);
      //         var value = udsupp[i];
      //         direct_premium[dSuppRowIndex][actualcol] += value;
      //     }
      // }
    }
    return direct_premium;
  }

  UpdateToPremiumReport(
    direct_premium,
    supplemental_premium,
    userdefined_premium,
    userdefined_premium_supplemental
  ) {
    //Updating user defined data in spremium
    var spremium = this.updateUDPremiumRow(
      'S',
      direct_premium,
      supplemental_premium,
      userdefined_premium,
      userdefined_premium_supplemental
    );
    direct_premium = spremium[0];
    supplemental_premium = spremium[1];

    //Updating supplemental totals
    supplemental_premium = this.updateColumnTotal(
      supplemental_premium,
      'S',
      supplemental_premium,
      userdefined_premium,
      userdefined_premium_supplemental
    );
    supplemental_premium = this.calculateRowTotal(supplemental_premium);

    //Updating user defined data in dpremium
    var dpremium = this.updateUDPremiumRow(
      'D',
      direct_premium,
      supplemental_premium,
      userdefined_premium,
      userdefined_premium_supplemental
    );
    direct_premium = dpremium[0];
    supplemental_premium = dpremium[1];

    // updating supplemental premium row in direct premium
    direct_premium = this.updateSuppPremiumRow(
      direct_premium,
      supplemental_premium,
      userdefined_premium_supplemental
    );

    direct_premium = this.updateColumnTotal(
      direct_premium,
      'D',
      supplemental_premium,
      userdefined_premium,
      userdefined_premium_supplemental
    );
    direct_premium = this.calculateRowTotal(direct_premium);
    return [direct_premium, supplemental_premium];
  }

  public getColWidth(
    colNumber: number,
    columns: any[],
    rowNumberColumn: number,
    stateColumn: number,
    stateCodeColumn: number
  ) {
    if (colNumber === rowNumberColumn) {
      return '50px';
    } else if (colNumber === stateColumn) {
      return '130px';
    } else if (colNumber === stateCodeColumn) {
      return '85px';
    } else if (colNumber === columns.length - 1) {
      return '150px';
    } else {
      return '114px';
    }
  }

  public selectMultipleCells(grid: IgxGridComponent) {
    if (grid.selectedCells.length) {
      const rowIndexes = [];
      const columnIDs = [];

      for (const cell of grid.selectedCells) {
        const rowIndex = cell.id.rowIndex;
        const columnID = cell.id.columnID;

        rowIndexes.push(rowIndex);
        columnIDs.push(columnID);
      }
      // Find minimum and maximum values for rows and columns
      const minRowIndex = Math.min(...rowIndexes);
      const maxRowIndex = Math.max(...rowIndexes);
      const minColumnID = Math.min(...columnIDs);
      const maxColumnID = Math.max(...columnIDs);

      return {
        rowStart: minRowIndex,
        rowEnd: maxRowIndex,
        columnStart: minColumnID,
        columnEnd: maxColumnID,
      };
    }
    return {};
  }

  public getFormula(
    columnInfo,
    rowIndex,
    isExcelExport,
    isLastCell,
    columns,
    supplemental_premium,
    userdefined_premium,
    userdefined_premium_columns,
    type: 'S' | 'D'
  ) {
    let formula = '';
    if (columnInfo?.cellformulae?.length) {
      if (columnInfo.cellformulae.indexOf(',') > 0) {
        const split = columnInfo.cellformulae.split(',');
        let formulaString = [];
        for (let index = 0; index < split.length; index++) {
          const element = split[index];
          formulaString.push(
            this.getFormulaString(element, rowIndex, isExcelExport)
          );
        }
        formula = '=' + formulaString.join('+');
        if (isExcelExport && !isLastCell) {
          if (columnInfo.columnNumber == columns.length - 1 && type == 'D') {
            const supplementalpremium =
              supplemental_premium[rowIndex - 2][columnInfo.columnNumber];
            if (supplementalpremium) {
              formula += '+' + supplementalpremium;
            }

            const userdefinedpremium =
              userdefined_premium[rowIndex - 2][
                userdefined_premium_columns.length - 1
              ];
            if (userdefinedpremium != null && userdefinedpremium != undefined) {
              formula += '+' + userdefinedpremium;
            }
          }
        }
      } else {
        formula =
          '=' +
          this.getFormulaString(
            columnInfo.cellformulae,
            rowIndex,
            isExcelExport
          );
      }
    }
    return formula;
  }

  public getFormulaString(formula, rowIndex, isExcelExport) {
    const [fFormula, lFormula] = formula.split(':');

    const formulaCellName1 = this.getColName(
      isExcelExport ? fFormula : fFormula - 1,
      rowIndex
    );
    const formulaCellName2 = this.getColName(
      isExcelExport ? lFormula : lFormula - 1,
      rowIndex
    );

    return `Sum(${formulaCellName1}:${formulaCellName2})`;
  }

  public getColName(colIndex, rowIndex) {
    let columnName = '';

    while (colIndex >= 0) {
      columnName = String.fromCharCode((colIndex % 26) + 65) + columnName;
      colIndex = Math.floor(colIndex / 26) - 1;
    }

    if (columnName.length) {
      columnName += rowIndex;
    }

    return columnName;
  }

  public getClipboardOptions() {
    return {
      enabled: true,
      copyHeaders: false,
      copyFormatters: true,
      separator: '\t',
    };
  }

  public findHiddenColumnCount = (columnID: number, columns: any[]) => {
    let hiddenCount = 0;
    for (let i = 0; i <= columnID; i++) {
      const column = columns[i];
      // Check if the column object exists and if its 'hidden' property is true
      if (column && column.hidden) {
        // Increment the hiddenCount if the column is hidden
        hiddenCount++;
      }
    }
    return hiddenCount;
  };

  public findHiddenColumnCountFromRange = (minColumnID: number, maxColumnID: number, columns: any[]) => {
    let hiddenCount = 0;

    for (let i = minColumnID; i <= maxColumnID; i++) {
      const column = columns[i];

      // Check if the column object exists and if its 'hidden' property is true
      if (column && column.hidden) {
        // Increment the hiddenCount if the column is hidden
        hiddenCount++;
      }
    }

    return hiddenCount;
  };
}



export class premiumreportParams {
  public columns = [];
  public rows = [];
  public userdefined_premium_columns = [];
  public const_statecode_column: number;
  public userdefined_row_code: number;
  public supplemental_row_code: number;
  public AssumedFromNonAffiliates_row_code: number;
  public const_fixed_columns: number;

  constructor(
    columns = [],
    rows = [],
    userdefined_premium_columns = [],
    const_statecode_column,
    userdefined_row_code,
    supplemental_row_code,
    const_fixed_columns,
    AssumedFromNonAffiliates_row_code
  ) {
    this.columns = columns;
    this.rows = rows;
    this.userdefined_premium_columns = userdefined_premium_columns;
    this.const_statecode_column = const_statecode_column;
    this.userdefined_row_code = userdefined_row_code;
    this.supplemental_row_code = supplemental_row_code;
    this.AssumedFromNonAffiliates_row_code = AssumedFromNonAffiliates_row_code;
    this.const_fixed_columns = const_fixed_columns;
  }
}
