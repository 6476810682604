import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { Router } from "@angular/router";
import { EntitlementService } from "./entitlement.service";
import { OAuthService } from "angular-oauth2-oidc";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class HttpService {
  nullobservable: Observable<string>;
  baseUrl = environment.apiUrl;
  Region = environment.enviroment_code;
  constructor( private cookieService: CookieService,private http: HttpClient, private router: Router, private entitlementService: EntitlementService, private oauthService: OAuthService) {
  }
  functionGet(url: string, args: any) {
    var ticket = this.getIsonetValue('ticket');
    if (ticket || this.Region == "D") {
      let finalUrl = this.baseUrl + url;
      try {
        return this.http.get(finalUrl, {
          headers: {
            'ticket': ticket,
          },
          params: new HttpParams().set(args[0], args[1])
        })
      } catch (error) {
        console.log("API request error on-" + url)
        //console.log(error);
      }
    }
    else {
      this.router.navigate(['/']);
      this.logout();
    }
  }


  functionPost(url: string, data: any) {
    var ticket = this.getIsonetValue('ticket');
    if (ticket || this.Region == "D") {
      try {
        return this.http.post(this.baseUrl + url, data, {
          headers: {
            'authorizationToken': ticket,
          }
        })
      } catch (error) {
        console.log("API request error on-" + url)
        //console.log(error);
      }
    }
    else {
      this.router.navigate(['/']);
      this.logout();
    }
  }
  functionDelete(url: string, data: any) {
    var ticket = this.getIsonetValue('ticket');
    if (ticket || this.Region == "D") {
      try {
        return this.http.delete(this.baseUrl + url, {
          headers: {
            'ticket': ticket,
          },
          params: new HttpParams().set('searchcriteria', data)

        })
      } catch (error) {
        console.log("API request error on-" + url)
        //console.log(error);
      }
    }
    else {
      this.router.navigate(['/']);
      this.logout();
    }
  }

  functionGetWithoutParams(url: string) {
    var ticket = this.getIsonetValue('ticket');

    if (ticket || this.Region == "D") {
      let finalUrl = this.baseUrl + url;
      try {
        return this.http.get(finalUrl, {
          headers: {
            'ticket': ticket,
          }
        })
      } catch (error) {
        console.log("API request error on-" + url)
        //console.log(error);
      }
    }
    else {
      this.router.navigate(['/']);
      this.logout();
    }
  }

  getIsonetValue(name:string){
    if(["prfcstid","cstid","customername","userid"].includes(name)){
      return localStorage.getItem(name);
    }
    
    return this.cookieService.get(name);
  }

  getCookieOld(name: string) {
    console.assert(false,"depricated service use getCokie insted")
    let ca: Array<string> = document.cookie.split(';');
    let cookieName = name + "=";
    let c: string;

    for (let i: number = 0; i < ca.length; i += 1) {
      if (ca[i].trim().startsWith(name)) {
        c = ca[i].substring(cookieName.length + 1, ca[i].length);
        return c;
      }
    }
    return "";
  }
  getcustid(url: string) {
    var ticket = this.getIsonetValue('ticket');

    if (typeof ticket != 'undefined' && ticket) {

      return this.http.get(url + ticket);
    }
    else if (this.Region == "D") {
      return this.nullobservable;
    }
    else {
      this.router.navigate(['/']);
      this.logout();
    }
  }
  logout() {

    console.info("httpservice-logout")
    localStorage.setItem("groupcode", "");
    localStorage.setItem("groupname", "");
    localStorage.setItem("companyId", "");
    localStorage.setItem("year", "");
    localStorage.removeItem('isLocked');
    localStorage.setItem("groupreportenabled", "");
    localStorage.setItem("prfName", "");
    localStorage.removeItem('prfNumber');
    localStorage.setItem("prfcstid", "");

    this.entitlementService.setLoginStatus(false);
    this.oauthService.logOut();
  }
}
