import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { Role } from './shared/models/user-model'

import { PageNotFoundComponent } from './error-routing/not-found/not-found.component';
import { UncaughtErrorComponent } from './error-routing/error/uncaught-error.component';
import { ErrorRoutingModule } from './error-routing/error-routing.module';
import { AuthGuard } from './AuthGuard';
import { AnnualstatementComponent } from './company/annualstatement/annualstatement.component';
import { CommentsComponent } from './company/comments/comments.component';
import { CompanyinformationComponent } from './company/companyinformation/companyinformation.component';
import { CompanylistComponent } from './company/companylist/companylist.component';
import { GroupreportingComponent } from './company/groupreporting/groupreporting.component';
import { HelppageComponent } from './helppage/helppage.component';
import { UserdefineddataComponent } from './company/userdefineddata/userdefineddata.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotFoundComponent } from './not-found/not-found.component'
import { CanDeactivateGuardComment, CanDeactivateGuardAnualStatement, CanDeactivateGuardPremiumReport, CanDeactivateGuardCompanyInfo, CanDeactivateGuardGroupReporting, CanDeactivateGuardUserdefineddata} from './shared/can-deactivate-guard.service'
import { EmailPreviewComponent } from './email-preview/email-preview.component';
import { ReportsubmissionComponent } from './reportsubmission/reportsubmission.component'
import { ApifailuremessageComponent } from './apifailuremessage/apifailuremessage.component'
import { AnnualstatementcomparisonComponent } from './admin/annualstatementcomparison/annualstatementcomparison.component'
import { ModalContainerComponent } from './opensubmissionmodal'
import { PremiumReportComponent } from './company/premiumreport/premium-report.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { WrapperComponent } from './navigation/wrapper/wrapper.component';
import { ValidationSummaryComponent } from './company/validation-summary/validation-summary.component';
import { PrioryearcomparisonComponent } from './admin/prioryearcomparison/prioryearcomparison.component';
import { RedirecttovalidationsummaryComponent } from './company/validation-summary/redirect-to-validation-summary/redirecttovalidationsummary/redirecttovalidationsummary.component';
export const routes: Routes = [
  // { path: '', redirectTo: 'dboard', pathMatch: 'full' },
  { path: '', component: LandingpageComponent, pathMatch: 'full',canActivate: [AuthGuard] },
  {
    path: '',
    component:WrapperComponent,
    children: [
      { path: 'dboard', component: DashboardComponent, canActivate: [AuthGuard]},
      { path: 'companyinformation', component: CompanyinformationComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardCompanyInfo] },
      { path: 'premiumreport', component: PremiumReportComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardPremiumReport] },
      // { path: 'premiumreport', component: PremiumreportComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardPremiumReport] },
      { path: 'groupreporting', component: GroupreportingComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardGroupReporting] },
      { path: 'annualstatement', component: AnnualstatementComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardAnualStatement] },
      { path: 'userdefineddata', component: UserdefineddataComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardUserdefineddata] },

      { path: 'annualstatementcomparison', component: AnnualstatementcomparisonComponent, canActivate: [AuthGuard] },

      { path: 'comments', component: CommentsComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuardComment] },
      { path: 'help', component: HelppageComponent, canActivate: [AuthGuard] },
      { path: 'emailpreview', component: EmailPreviewComponent, canActivate: [AuthGuard] },
      { path: 'PRFSubmission', component: ModalContainerComponent, canActivate: [AuthGuard] },
      { path: 'addcompanymodal', component: ModalContainerComponent, canActivate: [AuthGuard] },
      { path: 'apifailure', component: ApifailuremessageComponent,  canActivate: [AuthGuard]  },
      { path: 'validationsummary', component: ValidationSummaryComponent,  canActivate: [AuthGuard]  },
      { path: 'prioryearcomparison', component: PrioryearcomparisonComponent,  canActivate: [AuthGuard]  },
      { path: 'redirecttovalidationsummary', component: RedirecttovalidationsummaryComponent,  canActivate: [AuthGuard]  },

    ]

  },
  {
    path: 'admin', canLoad: [AuthGuard], canActivate: [AuthGuard],
    component:WrapperComponent,
    data: {
      roles: [
        Role.Admin,
      ]
    },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  // { path: '**', redirectTo: 'dboard', pathMatch: 'full' }
  { path: '**', component: LandingpageComponent, pathMatch: 'full',canActivate: [AuthGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), ErrorRoutingModule],
  exports: [RouterModule, ErrorRoutingModule]
})
export class AppRoutingModule {
}
