<app-loading-screen *ngIf="spinnergetprioryearcomparison">Loading Prior Year Comparison Report</app-loading-screen>
<form>
    <div class="pagetitle">Prior Year Comparison: {{ prfYear }}</div>

    <div *ngIf="compno!==null">
        <span class="pagetitleSecondLayerBold"> {{ prfNumber }} </span>:<span class="pagetitleSecondLayer">
            {{ prfName }}</span>
        <a *ngIf="!isCustomer" class="interactive-links emailBtn" (click)="email()"><span style="padding-right: 0.5ex;"
                class="table-material-icons">email</span>Email</a>
    </div>
    <br/>
    
    <div class="information" *ngIf="showPageError">
        <div class="messagetitle"><i class="table-material-icons">error</i> {{pageError}}</div>
    </div>

    <form>
        <div class="form-group">
           
            <div class="form-inline">
                <label  style="font-family: 'Roboto-Bold', sans-serif;margin-top: 20px;" for="pRFNumberInput">Compare To:</label>
                <label class="lib-text-field" style="margin-top: 10px;margin-left: 10px;">
                    <input [(ngModel)]="compareToPrfNumber" name="compareToPrfNumber" placeholder="SEARCH PRF NUMBER"
                        id="search" style="width: 100%;" />
                </label>
                <app-button buttonType=""primary style="margin-left: 15px;margin-top: 10px;"
                    (click)="compareTo()" [disabled]="compareToPrfNumber==''">Compare</app-button>
                    
            </div>
            <div *ngIf="flagNote" class="note" style="margin-left: auto;margin-right: 0;"><i class="table-material-icons">flag</i> =
                Difference between Current year and Prior year premiums</div>
        </div>
    </form>

    <div>
        <!-- <div *ngIf="flagNote" class="note"><i class="table-material-icons">flag</i> =
            Difference between Current year and Prior year premiums</div> -->
        <table class="table table-hover">
            <thead>
                <tr class="grid-header">
                    <td></td>
                    <td style="color:#333333!important;">[All Premiums In Thousands]</td>
                    <td colspan="2" style="text-align: center;"><strong>Gross Premiums in (000s)</strong></td>
                    <td style="text-align: left;"><strong>%</strong></td>

                </tr>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Line Of Insurance</th>
                    <th scope="col">{{prfYear-1}} Premium Report Form</th>
                    <th scope="col">{{prfYear}} Premium Report Form</th>
                    <th scope="col">Premium Difference</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of priorYearComparisonList;let row=index">
                    <td>{{ (item.lineofinsuraance!=='Total') ? row+1 : ""}}</td>
                    <td>{{item.lineofinsuraance}} </td>
                    <td>{{item.priorpremium | currency :'USD':symbol:'1.0-2'}}</td>
                    <td>{{item.currpremium | currency :'USD':symbol:'1.0-2'}}</td>
                    <td>
                        <div *ngIf="item.premiumdiff==0;else texttemp">
                            {{item.premiumdiff}}</div>
                        <ng-template #texttemp>
                            <div *ngIf="item.premiumdiff=='Increase > 100' || item.premiumdiff=='Decrease > 100';else valuetemp"
                                style="font-weight: bold;">{{item.premiumdiff}} <i *ngIf="item.mark !==''"
                                    class="table-material-icons">flag</i></div>
                        </ng-template>
                        <ng-template #valuetemp>
                            <div>{{item.premiumdiff}} <i *ngIf="item.mark !==''"
                                    class="table-material-icons">flag</i>
                            </div>
                        </ng-template>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</form>