import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { CompanyService } from 'src/app/company/company.service';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../../app.service'
import { AdobelaunchService } from '../../_services/adobelaunch.service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-annualstatementcomparison',
  templateUrl: './annualstatementcomparison.component.html',
  styleUrls: ['./annualstatementcomparison.component.scss']
})
export class AnnualstatementcomparisonComponent implements OnInit, AfterViewInit {

  currentYear: number;
  prfNumber: string;
  prfName: string;
  groupCode: string;
  compno: any;
  annualstatementlist: any[] = [];
  ascomparisonreportform: UntypedFormGroup;
  show: boolean = true;
  isCustomer: boolean = true;
  iconNote: boolean = false;

  constructor(private adminService: AdminService,
    private companyService: CompanyService,
    private appService: AppService,
    private router: Router, private AdobelaunchService: AdobelaunchService, private httpService: HttpService) { }

  ngAfterViewInit() {
    this.AdobelaunchService.EventEndDispatch();
  }
  ngOnInit(): void {
    this.compno = this.companyService.companyId;
    this.currentYear = this.companyService.currentYear;
    this.prfNumber = this.companyService.prfNumber;
    this.prfName = this.companyService.prfName;
    let Cookiecstid = this.httpService.getIsonetValue("cstid");
    let globalprfcustid = localStorage.getItem('prfcstid')
    //var globalprfcustid =this.companyService.getcustid();
    if (typeof globalprfcustid != 'undefined' && globalprfcustid) {
      if (+globalprfcustid == environment.MasterId || +globalprfcustid == parseInt(environment.ViewerId)) {
        this.isCustomer = false;
      }
      else {
        this.isCustomer = true;
      }
    }
    else {
      if (Cookiecstid) {
        let url = environment.IsoAuthService + "/GetSessionProductGroupMembershipAndCustomerIds/";
        this.httpService.getcustid(url).subscribe((resp) => {
          let splitted = resp.toString().split("|");
          let custid = splitted.find(x => x.includes("PRF")) || "";
          if (custid != "") {
            let cstid = custid.toString().split(",")[0];
            localStorage.setItem("prfcstid", cstid);
            if (+cstid == environment.MasterId || +cstid == parseInt(environment.ViewerId)) {
              this.isCustomer = false;
            }
            else {
              this.isCustomer = true;
            }
          }
        },
          (error) => {
            console.log("ISOnet product eligibility check error-Annual Statement Comparison.")
            // //console.log(error);
          }
        );

      }
    }
    // this.isCustomer = this.appService.isCustomer;


    this.companyService.getgroup().subscribe((value) => {
      this.groupCode = value;
    });

    this.loadAnnualStatementreport();
    this.getPrgress();
  }

  getPrgress() {
    this.companyService.getCustomerPrfProgress(this.currentYear, this.compno, this.groupCode).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.companyService.disablePrf.next(false);
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  loadAnnualStatementreport() {
    this.annualstatementlist = [];
    this.adminService.loadAnnualStatementComparison(this.currentYear, this.groupCode, this.compno).subscribe((resp) => {
      if (resp["message"] == "Success") {
        this.show = false;
        this.annualstatementlist = resp["ascomparisondata"];
        let index = this.annualstatementlist.indexOf("mailid");
        this.annualstatementlist.splice(index, 1);
        for (let i = 0; i < this.annualstatementlist.length; i++) {
          if (this.annualstatementlist[i]['mark'] != "")
            this.iconNote = true;
        }
      }
    },
      (error) => {
        // //console.log(error);
        this.router.navigate(['/apifailure']);
      }
    )
  }

  email() {
    localStorage.setItem("routename", "annualstatement");
    this.router.navigate(['/emailpreview'])
  }


}
